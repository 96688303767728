.app-content {
  margin-top: 64px;
}

.footer-style {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
